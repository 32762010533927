import React from 'react';

import { reverse } from 'named-urls';

import routes from 'core/routes';

import { useStore } from 'utils/hooks/store';

import {
  CardButton,
  CardButtonLink,
  CardCell,
  CardDescription,
  CardTitle,
  CardsContainer,
  MobileBadgesContainer,
} from './styles';

import googlePlayBadge from '../../../../../../assets/images/googlePlayBadge.png';
import appStoreBadge from '../../../../../../assets/images/appStoreBadge.png';

const UserAlarmsPane: React.FunctionComponent = () => {
  const {
    session: { user },
  } = useStore();
  return (
    <CardsContainer
      aria-label="user-role-alarms-pane"
      $addTopMargin={user?.licenses.protectorIncidents || user?.licenses.workflows}
    >
      <CardCell>
        <CardTitle>My Profile</CardTitle>
        <CardDescription>Edit your details and change your password.</CardDescription>
        <div>
          <CardButtonLink href={reverse(routes.dashboard.admin.users.details, { userId: user?.uuid as string })}>
            <CardButton variant="contained" color="primary">
              View Profile
            </CardButton>
          </CardButtonLink>
        </div>
      </CardCell>
      <CardCell>
        <CardTitle>Help Centre</CardTitle>
        <CardDescription>
          Learn how to use our products, fix a problem, and get answers to your questions.
        </CardDescription>
        <div>
          <CardButtonLink href="https://vatix.helpscoutdocs.com/">
            <CardButton variant="contained" color="primary">
              Visit Help Centre
            </CardButton>
          </CardButtonLink>
        </div>
      </CardCell>
      <CardCell>
        <CardTitle>Mobile App</CardTitle>
        <CardDescription>You can download our mobile app for iOS and Android devices.</CardDescription>
        <MobileBadgesContainer>
          <a href="https://apps.apple.com/us/app/protector-mobile-by-vatix/id1455093767">
            <img src={appStoreBadge} alt="Download on the App Store" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.vatix.mobile">
            <img src={googlePlayBadge} alt="GET IT ON Google Play" />
          </a>
        </MobileBadgesContainer>
      </CardCell>
    </CardsContainer>
  );
};

export default UserAlarmsPane;
