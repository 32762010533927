import styled from 'styled-components/macro';

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 8px;
  max-width: 304px;
`;

export const InfoDescription = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
  opacity: 0.8;
  margin: 0;
`;

export const ColumnHeader = styled.div`
  font-weight: 500;
  display: flex;
  align-items: center;
`;
