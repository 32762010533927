import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  background-color: #ffffff;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
`;

export const Container = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 20px;
  padding-bottom: 0;
  background-color: #ffffff;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
`;

export const OptionContainer = styled.a`
  align-items: center;
  display: flex;
  padding: 9px 16px;
  padding-bottom: 0;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
`;

export const OptionLine = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

export const Line = styled.hr`
  margin: 0;
  margin-top: 8px;
  margin-bottom: -1px;
  border-radius: 10px;
  border: 1px solid #0772e5;
  width: 100%;
`;

export const OptionLabel = styled.p<{ active: boolean }>`
  margin: 0;
  color: ${(props) => (props.active ? '#0772E5' : '#00000099')};
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.4px;
`;
