import styled from 'styled-components/macro';

export const TabTitle = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin: 0;
`;

export const PersonalDetailsWrapper = styled.div`
  margin: 8px 20px 12px;
`;
