import styled from 'styled-components/macro';
import { Autocomplete } from '@mui/material';

import VatixTheme from 'theme/vatix';

export const StyledAutocomplete = styled(Autocomplete)`
  appearance: none;
  background-color: white;
  color: ${VatixTheme.palette.text.secondary};

  .MuiAutocomplete-inputRoot {
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 1.5em;
    font-family: Roboto, sans-serif;
  }

  .MuiAutocomplete-option {
    font-size: 1.5em;
    font-family: Roboto, sans-serif;
    color: red;
  }
`;

export const StyledOption = styled.li`
  font-size: 1.5em;
  font-family: Roboto, sans-serif;
  min-height: 24px !important;
`;
