import styled from 'styled-components/macro';
import { CancelButton } from 'vatix-ui/lib/components/Button';

export const MembersRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
`;

export const SpaceBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

export const MembersHeader = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
`;

export const RemoveBtn = styled(CancelButton)`
  border: none;
  padding: 0;
`;

export const Container = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
`;
