import styled from 'styled-components/macro';

import VatixTheme from 'theme/vatix';

export const SectionContainer = styled.div`
  padding: 20px;
  width: 100%;
`;

export const AlarmResponseProfileContainer = styled.div<{ first?: Boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 8px 0;
  align-items: center;
`;

export const AlarmResponseProfileDetails = styled.div`
  color: ${VatixTheme.palette.text.secondary};
  width: 100%;

  a {
    color: ${VatixTheme.palette.primary.main};
    text-decoration: none;
  }

  display: flex;
  padding: 20px;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 8px;
  background: #fafafa;
`;

export const AlarmResponseProfileTitle = styled.div`
  margin: 0;
  font-size: 1.4rem;
  line-height: 1.8rem;
`;

export const AlarmResponseProfileValue = styled.p`
  margin: 0;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
`;

export const AlarmResponseProfileField = styled.div`
  margin: 0;
  font-size: 1.3rem;
  line-height: 1.8rem;
  color: ${VatixTheme.palette.text.secondary};
  :not(:first-child) {
    margin-top: 6px;
  }
`;

export const AlarmResponseProfileOrder = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid #eee;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #0772e5;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 166%; /* 24.9px */
  letter-spacing: 0.4px;
  margin-right: 10px;
`;

export const AlarmResponseProfileHeader = styled.p`
  font-weight: 600;
  margin: 0;
  height: 16px;
  align-self: stretch;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
`;

export const Empty = styled.p`
  font: 14px/20px Roboto;
  letter-spacing: 0px;
  color: #6d7883;
  margin: 0;
`;
