import styled from 'styled-components/macro';

import { center } from 'core/styles';

export const ZoomControlContainer = styled.div`
  height: 70px;
  width: 32px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: #868585;
  display: flex;
  flex-direction: column;
  margin: 29px;
`;

export const ZoomControlButton = styled.a`
  flex-grow: 1;
  ${center};
`;
