import styled from 'styled-components/macro';

export const CommentLayout = styled.div`
  padding: 20px 24px;
`;

export const ScrollableContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  flex-grow: 1;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
`;
