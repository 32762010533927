import styled from 'styled-components/macro';
import Paper from '@mui/material/Paper';

import { center } from 'core/styles';

export const SearchControlPaper = styled(Paper)`
  margin: 29px;
  height: 36px;
  ${center};
  padding-left: 12px;
`;
