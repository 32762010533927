import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components/macro';

export const useStyles = makeStyles({
  digitInput: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
});

export const StyledTextField = styled(TextField)<{
  hasError?: boolean;
}>`
  input {
    width: 65px;
    height: 65px;
    font-size: 20px;
    ${(props) => props.hasError && 'border: 2px solid #D32F2F; border-radius: 4px;'}
  }
`;
