import { useHistory } from 'react-router-dom';

import React from 'react';
import { reverse } from 'named-urls';
import { GridEventListener, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';

import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import { VatixError } from 'vatix-ui/lib/components/Error/types';

import routes from 'core/routes';

import Grid, { defaultFormatters, defaultOperators, defaultRenderers } from 'components/Grid';
import { ColumnDefinition } from 'components/Grid/types';

import { ListRendererType } from 'components/Grid/renderers/GridListRenderer/types';
import { listRenderer } from 'components/Grid/renderers/GridListRenderer';
import { Container as GridChipContainer } from 'components/Grid/renderers/GridChipRenderer/styles';

import Search from 'components/Grid/operators/Search';
import API from 'utils/api/api';

import Chip from 'components/Chip';

import DeviceTypeCell from '../DeviceTypeCell';

const basicColumns = ([
  {
    field: 'type',
    headerName: 'Device',
    filterOperators: Search(API.searchDevices, API.loadDeviceDetails, 'customId'),
    renderCell: DeviceTypeCell,
    valueGetter: ({ value, row }: GridValueGetterParams) => ({ type: value, customId: row.customId }),
    nativeFilter: 'devices',
  },
  {
    field: 'tags',
    headerName: 'Device tags',
    filterOperators: Search(API.searchDeviceTags, API.deviceTagsDetails, 'name'),
    renderCell: listRenderer(
      ((props) => (
        <GridChipContainer>
          <Chip
            // @ts-ignore
            label={props.value.value.name}
            size="small"
            variant="filled"
            colors={{ background: 'rgba(7, 114, 229, 0.08)', foreground: 'rgba(1, 96, 199, 1)' }}
          />
        </GridChipContainer>
      )) as ListRendererType,
      3,
      undefined,
      { empty: '-', customEmptyText: '-' }
    ),
    nativeFilter: 'device_tags',
  },
  {
    defaultVisibility: false,
    field: 'groups',
    headerName: 'Teams',
    filterOperators: Search(API.searchGroups, API.loadTeamDetails, 'name'),
    renderCell: listRenderer(defaultRenderers.short_text as ListRendererType, 3, (values: unknown[]) =>
      values.join(', ')
    ),
    valueGetter: ({ value }: GridValueGetterParams) => value.map((item: UuidableName) => item.name),
    nativeFilter: 'teams',
  },
  {
    field: 'user',
    headerName: 'Current user',
    filterOperators: defaultOperators.user(),
    renderCell: defaultRenderers.user,
    valueFormatter: defaultFormatters.user,
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
    nativeFilter: 'users',
  },
] as unknown) as ColumnDefinition[];

const DeviceList: React.FunctionComponent<{ onError: (error: VatixError) => void }> = ({
  onError,
}): React.ReactElement => {
  const history = useHistory();
  const onRowClick: GridEventListener<'rowClick'> = (task: GridRowParams): void => {
    history.push(reverse(routes.dashboard.alarms.devices.details, { deviceId: task.row.uuid }));
  };

  return (
    <Grid
      basicColumns={basicColumns}
      entity="device"
      onError={onError}
      onRowClick={onRowClick}
      dataURL="/api/devices/"
    />
  );
};

export default DeviceList;
