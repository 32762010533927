import styled from 'styled-components/macro';

import { SectionGrid } from 'components/BackgroundInformation/styles';

export const ProductLicencesGroup = styled.div`
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  display: flex;
  padding: 12px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);

  p {
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.15px;
    margin: 0;
  }
  div {
    padding: 0 16px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
`;

export const RowValue = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin: 0;
`;

export const StyledSectionGrid = styled(SectionGrid)`
  margin-top: 15px;
  min-height: 24px;
`;

export const StyledRowTitle = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 600;
  line-height: 143%;
  letter-spacing: 0.17px;
  margin: 0;
`;
