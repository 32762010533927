import { Link } from '@mui/material';
import styled from 'styled-components/macro';

export const Container = styled.div`
  border-radius: 4px;
  border: 1px solid #e2e4e6;
  background: #fff;
  margin-bottom: 10px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 16px 20px 16px 20px;
`;

export const Header = styled.h1`
  color: var(--light-text-primary, rgba(0, 0, 0, 0.87));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  letter-spacing: 0.15px;
  margin: 0;
  padding: 0;
`;

export const DeviceRecordContainer = styled.div<{ $editing: boolean }>`
  padding: 6px 20px 20px;
  background-color: #fff;
  padding-top: ${(props) => props.$editing && '0'};
  border-radius: 4px;
`;

export const DeviceRecordRow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RecordRow = styled.div`
  display: flex;
  align-items: flex-start;
  min-height: 24px;
`;

export const RowValue = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin: 0;
`;

export const FirstColumn = styled.div`
  flex: 1;
  padding: 10px 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 600;
  line-height: 143%;
  letter-spacing: 0.17px;
`;

export const SecondColumn = styled.div`
  flex: 3;
  padding: 10px 0;
`;

export const DevicePicture = styled.img`
  width: 24px;
  height: 24px;
`;

export const StyledName = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin: 0;
`;

export const StyledId = styled.p`
  color: rgba(0, 0, 0, 0.6);
  /* Typography/Body 1 */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin: 0;
  margin-left: 4px;
`;

export const MapButtonLink = styled(Link)`
  color: #0772e5;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.46px;
  cursor: pointer;
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: 10px;
`;
