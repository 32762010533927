import styled from 'styled-components/macro';

import { columnFlex } from 'core/styles';

export const EnchantedMapContainer = styled.div`
  &,
  & .google-maps-container {
    ${columnFlex};
    padding: 20px;
    background: #fff;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
  }
`;
