import { styled } from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid var(--light-other-divider, rgba(0, 0, 0, 0.12));
  background: #fff;
  padding-bottom: 20px;
  :last-child {
    border: 0;
  }
`;

export const SettingsRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 0px 20px 0px 20px;
`;

export const SettingsRecap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 6px;
`;

export const SettingsName = styled.label`
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 143%;
  letter-spacing: 0.17px;
  margin: 0;
`;

export const SettingsDescription = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  margin: 4px 0px 0px 0px;
`;

export const Header = styled.div`
  display: flex;
  padding-top: 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 16px;
  padding-left: 16px;

  h1 {
    color: rgba(0, 0, 0, 0.87);
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    padding: 0;
    margin-bottom: 4px;
  }
  p {
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }
`;
