import styled from 'styled-components/macro';

import { TextField } from '@mui/material';

export const StyledDigitTextField = styled(TextField)`
  color: #101d31;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;

  .MuiInputBase-root {
    border-radius: 4px;
    border: ${(props) => (props.error ? '2px' : '1px')} solid
      ${(props) => (props.error ? '#D32F2F' : 'rgba(0, 0, 0, 0.23)')};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
    letter-spacing: 0.15px;
    height: 100%;
  }
`;

export const DigitContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 66px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

export const HelpContainer = styled.div`
  border-radius: 4px;
  background: #e5f3fa;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  padding: 16px 16px;
  width: 100%;
  display: flex;
`;

export const ErrorContainer = styled(HelpContainer)`
  background: #fbeaea;
  color: #c62828;
  padding: 12px 16px;
`;

export const StyledErrorText = styled.div`
  margin-left: 12px;
  margin-top: 2px;
`;

export const VerificationCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;
