import styled from 'styled-components/macro';

import { SectionGrid } from 'components/BackgroundInformation/styles';

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-content: center;
  padding: 8px 0;
`;

export const StyledRowTitle = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 600;
  line-height: 143%;
  letter-spacing: 0.17px;
  margin: 0;
`;

export const StyledSectionGrid = styled(SectionGrid)`
  min-height: 24px;
`;
