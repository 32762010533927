import styled from 'styled-components/macro';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl } from '@mui/material';

export const StyledFormControl = styled(FormControl)`
  margin: 8px 0;
`;

export const ConfirmationDialogWrapper = styled(Dialog)`
  .MuiPaper-root {
    padding: 16px 6px;
    width: 600px;
  }
`;

export const ConfirmationDialogTitle = styled(DialogTitle)`
  font-weight: 500;
  font-size: 20px;
  padding: 16px 12px;
  color: rgba(0, 0, 0, 0.87);
`;

export const ConfirmationDialogContent = styled(DialogContent)`
  padding: 16px 12px;
`;

export const ConfirmationDialogContentText = styled(DialogContentText)`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
`;

export const PrimaryDialogActions = styled(DialogActions)`
  justify-content: flex-start;
  margin-left: 0;
  padding: 0px 12px;
`;
