import styled from 'styled-components/macro';

export const EmptyContainer = styled.div`
  background: #ffffff;
  border-radius: 8px;
  font-size: 14px;
  text-align: center;

  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    margin: 0;
    color: rgba(0, 0, 0, 0.87);
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.15px;
  }

  p {
    color: rgba(0, 0, 0, 0.6);
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.15px;
  }
`;
