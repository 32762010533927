import React from 'react';
import { ThemeContext } from 'styled-components/macro';

import { useStore } from 'utils/hooks/store';

import PopUpRedAlerts from '../PopUpRedAlerts';
import ResolutionDialog from '../ResolutionDialog';
import NavBar from './components/NavBar';
import SideBar from './components/SideBar';
import { Container, Content } from './styles';
import Routing from './components/Routing';
import Config from '../Config';

// regexp for checking if current view is details view
const re = new RegExp(/\/\S+\/[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}\/$/);

const Dashboard: React.FunctionComponent = (): React.ReactElement => {
  const themeContext = React.useContext(ThemeContext);
  const { routing } = useStore();

  React.useEffect(() => {
    document.body.style.backgroundColor = themeContext ? themeContext.palette.grey['300'] : 'white';

    return () => {
      document.body.style.backgroundColor = themeContext ? themeContext.palette.common.white : 'white';
    };
  }, [themeContext]);

  const isDetailsView = re.test(routing.location.pathname);

  return (
    <Container>
      <Config>
        <SideBar />

        <Content>
          <div id="nav-bar">
            <NavBar />
          </div>
          <Routing isDetailsView={isDetailsView} />

          <PopUpRedAlerts />

          <ResolutionDialog />
        </Content>
      </Config>
    </Container>
  );
};

export default Dashboard;
