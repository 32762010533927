import { Chip as MUIChip } from '@mui/material';
import styled from 'styled-components/macro';

export const StyledChip = styled(MUIChip)<{
  $colors?: {
    background?: string;
    foreground?: string;
  };
  fontSize?: number;
  size?: 'small' | 'medium';
  $labelPaddingLeft?: number;
  $labelPaddingRight?: number;
  $displayPointer: boolean;
}>`
  background-color: ${(props) => props.$colors?.background ?? '#00000014'};
  color: ${(props) => props.$colors?.foreground ?? '#000000DE'};
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '13px')};
  height: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;
  span {
    padding-left: ${(props) => props.$labelPaddingLeft}px;
    padding-right: ${(props) => props.$labelPaddingRight}px;
  }
  cursor: ${(props) => (props.$displayPointer ? 'pointer' : 'default')};

  ::first-letter {
    text-transform: capitalize;
  }
`;

const IconContainer = styled.div<{
  iconBackgroundColor?: string;
  $noLabelMargin?: boolean;
  $disableMargin?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  svg {
    max-width: 20px;
    max-height: 20px;
  }
  img {
    max-width: 20px;
    max-height: 20px;
  }
  ${(props) =>
    props.iconBackgroundColor
      ? `
    background-color: ${props.iconBackgroundColor};
    border-radius: 50%;
  `
      : ''}
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  ${({ $disableMargin, $noLabelMargin }) =>
    $disableMargin ? 'margin: 0px !important;' : `margin-left: ${$noLabelMargin ? '2px' : '4px'} !important;`}
`;

export const IconLeftContainer = styled(IconContainer)`
  margin-right: ${(props) => (props.$noLabelMargin ? '2px' : '-4px')} !important;
`;

export const IconRightContainer = styled(IconContainer)`
  margin-right: ${(props) => (props.$noLabelMargin ? '2px' : '4px')} !important;
`;
