import styled from 'styled-components/macro';

export const ButtonWrapper = styled.div`
  display: flex;
  padding: 0 15%;
  justify-content: space-between;
`;

export const Container = styled.div`
  @media (min-width: 680px) {
    padding: 0px 15%;
  }
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

export const SubmissionContainer = styled.div`
  display: flex;
  position: relative;
  flex-flow: column;
  padding: 20px;
  gap: 20px;
  align-self: stretch;
`;

export const IncidentOverviewNoSectionTitle = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.15px;
  padding-top: 20px;
  padding-bottom: 10px;
`;

export const IncidentOverviewNoSection = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 10px 20px 0px 20px;
  margin-top: -10px;
  @media (max-width: 768px) {
    border-radius: 10px;
    margin: 0 16px;
  }
`;
