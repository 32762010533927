import styled from 'styled-components/macro';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px 4px 0 0;
  h1 {
    margin: 0;
    padding: 0;
    font-size: 18px;
  }
`;

export const Content = styled.div`
  background: #fff;
  border-radius: 8px;
  padding-bottom: 4px;
`;
