import styled from 'styled-components/macro';

export const TasksHeader = styled.h1`
  color: #101d31;
  font: 14px/16px Roboto;
  font-weight: 500;
  letter-spacing: 0px;
  margin: 0;
  margin-bottom: 8px;
`;

export const NewTaskLink = styled.a`
  color: #0772e5;
  font: 14px/16px Roboto;
  font-weight: 500;
  letter-spacing: 0px;
  margin-top: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const TaskContainer = styled.div`
  margin-bottom: 16px;
  padding: 4px 16px;
  padding-right: 0;
  border-radius: 8px;
  background: #f5f5f5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;
