import React from 'react';
import { observer } from 'mobx-react';

import { GridValueGetterParams } from '@mui/x-data-grid';

import { useRouteMatch } from 'react-router-dom';

import { Divider } from '@mui/material';

import Grid, { defaultOperators, defaultRenderers } from 'components/Grid';

import { ColumnDefinition } from 'components/Grid/types';

import { Container, Header, GridContainer } from './styles';

const basicColumns = ([
  {
    field: 'user',
    headerName: 'Name',
    nativeFilter: false,
    renderCell: defaultRenderers.user,
    filterOperators: defaultOperators.user(),
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
  },
  {
    field: 'assigned',
    headerName: 'Assigned',
    nativeOrdering: 'created',
    nativeFilter: true,
    renderCell: defaultRenderers.date_time,
    sortable: true,
    filterOperators: defaultOperators.date_time(),
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
  },
  {
    field: 'unassigned',
    headerName: 'Unassigned',
    renderCell: defaultRenderers.date_time,
    sortable: true,
    filterOperators: defaultOperators.date_time(),
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
  },
] as unknown) as ColumnDefinition[];

const DeviceHistory: React.FunctionComponent = () => {
  const match = useRouteMatch();
  const { deviceId } = match.params as { deviceId: string };

  return (
    <Container>
      <Header>History</Header>
      <Divider />
      <GridContainer>
        <Grid
          basicColumns={basicColumns}
          dataURL={`/api/devices/${deviceId}/assignments/`}
          showHeader={false}
          disableColumnMenu
          sortBy={{ field: 'assigned', sort: 'desc' }}
          disableSelectAllColumns
          sortingMapping={{
            assigned: 'created',
            unassigned: 'end__created',
          }}
        />
      </GridContainer>
    </Container>
  );
};

export default observer(DeviceHistory);
