import styled from 'styled-components/macro';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { TextInput } from 'vatix-ui/lib/components/TextInput';
import { Dropdown } from 'vatix-ui/lib/components/Dropdown';

export const FilterItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const ColumnsContainer = styled.div`
  display: flex;
  align-items: center;

  width: 152px;
`;

export const OperatorContainer = styled(ColumnsContainer)`
  width: 120px;
`;

export const InputContainer = styled(ColumnsContainer)`
  width: 400px;

  .MuiInputBase-root {
    font-size: 14px;
    height: 40px;
    border: none;
  }

  .MuiInputBase-input {
    font-size: 14px;
  }

  .MuiAutocomplete-input {
    padding: 0 14px !important;
  }
`;

export const StyledInputLabel = styled(InputLabel)`
  font-size: 14px;
  transform: translate(10px, 10px);
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
  max-width: 256px;
  white-space: break-spaces;
`;

export const DisabledTextInput = styled(TextInput)`
  background-color: #f1f0f0;
  width: 100%;
`;

export const EntityContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EntityName = styled.p`
  margin: 0;
  height: 14px;
  font-size: 12px;
  margin-bottom: 5px;
  color: rgba(0, 0, 0, 0.6);
`;

export const StyledDropdown = styled(Dropdown)`
  maxheight: 400;
`;
