import styled, { css } from 'styled-components/macro';
import { MenuItem } from '@mui/material';

import { ToastContainer } from 'react-toastify';

export const center = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ellipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const columnFlex = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 1.5rem;
`;

export const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    align-items: center;
    padding: 6px 16px;
    min-height: 52px;
    min-width: 420px;
  }
  .Toastify__toast-body {
    padding: 0;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
  }
  .Toastify__toast-body > div:last-child {
    margin-right: 12px;
  }
  .Toastify__toast-theme--colored.Toastify__toast--default {
    background-color: rgba(255, 255, 255, 0.9);
    color: rgba(46, 125, 50, 1);
    .Toastify__close-button > svg {
      fill: rgba(56, 255, 50, 1);
    }
  }
  .Toastify__toast-theme--colored.Toastify__toast--info {
    background-color: rgba(229, 243, 250, 1);
    color: rgba(7, 114, 229, 1);
    .Toastify__close-button > svg {
      fill: rgba(56, 5, 250, 1);
    }
  }
  .Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: rgba(233, 242, 234, 1);
    color: rgba(46, 125, 50, 1);
    .Toastify__close-button > svg {
      fill: rgba(46, 125, 50, 1);
    }
  }
  .Toastify__toast-theme--colored.Toastify__toast--warning {
    background-color: rgba(253, 240, 230, 1);
    color: rgba(237, 108, 2, 1);
    .Toastify__close-button > svg {
      fill: rgba(5, 5, 250, 1);
    }
  }
  .Toastify__toast-theme--colored.Toastify__toast--error {
    background-color: rgba(251, 234, 234, 1);
    color: rgba(211, 47, 47, 1);
    .Toastify__close-button > svg {
      fill: rgba(255, 5, 50, 1);
    }
  }
`;
