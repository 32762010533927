import styled from 'styled-components/macro';

export const QuestionsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: fit-content;
`;

export const EmptyAnswerText = styled.p`
  color: rgba(0, 0, 0, 0.38);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin: 0;
`;

export const AnswerText = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin: 0;
`;
