import styled from 'styled-components/macro';

export const QuestionAnswer = styled.p`
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
`;
