import { useHistory } from 'react-router-dom';

import React from 'react';
import { reverse } from 'named-urls';
import { GridEventListener, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';

import { VatixError } from 'vatix-ui/lib/components/Error/types';

import routes from 'core/routes';

import Grid, { defaultFormatters, defaultOperators, defaultRenderers } from 'components/Grid';
import { ColumnDefinition, FilterType } from 'components/Grid/types';

import { ListRendererType } from 'components/Grid/renderers/GridListRenderer/types';
import { listRenderer } from 'components/Grid/renderers/GridListRenderer';

import Search from 'components/Grid/operators/Search';
import API from 'utils/api/api';

import { EmptyValue } from 'containers/IncidentDetails/components/CustomFieldDisplayValue/styles';

import DeviceTypeCell from 'containers/Devices/components/DeviceTypeCell';
import { formatDateForDisplay } from 'utils/formatters/time';
import { Container as GridChipContainer } from 'components/Grid/renderers/GridChipRenderer/styles';

import Chip from 'components/Chip';

import { StyledCreated, Container } from './styles';

const basicColumns = ([
  {
    field: 'type',
    headerName: 'Device',
    filterOperators: Search(API.searchDevices, API.loadDeviceDetails, 'customId'),
    renderCell: DeviceTypeCell,
    valueGetter: ({ value, row }: GridValueGetterParams) => ({ type: value, customId: row.customId }),
    nativeFilter: 'devices',
  },
  {
    field: 'tags',
    headerName: 'User tags',
    filterOperators: Search(API.searchDeviceTags, API.deviceTagsDetails, 'name'),
    renderCell: listRenderer(
      ((props) => (
        <GridChipContainer>
          <Chip
            // @ts-ignore
            label={props.value.value.name}
            size="small"
            variant="filled"
            colors={{ background: 'rgba(7, 114, 229, 0.08)', foreground: 'rgba(1, 96, 199, 1)' }}
          />
        </GridChipContainer>
      )) as ListRendererType,
      3
    ),
    nativeFilter: 'device_tags',
  },
  {
    field: 'user',
    headerName: 'User',
    filterOperators: defaultOperators.user(),
    renderCell: defaultRenderers.user,
    valueFormatter: defaultFormatters.user,
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
    nativeFilter: 'users',
    sortable: true,
  },
  {
    field: 'lastPoll',
    filterOperators: defaultOperators.date(),
    headerName: 'Last online',
    renderCell: ({ value }: { value: string }) =>
      value ? <StyledCreated>{formatDateForDisplay(value)}</StyledCreated> : <EmptyValue>-</EmptyValue>,
    sortable: true,
    nativeFilter: true,
    valueFormatter: defaultFormatters.date,
  },
] as unknown) as ColumnDefinition[];

const DeviceList: React.FunctionComponent<{
  onError: (error: VatixError) => void;
  filters: FilterType[];
  onRefreshCallback: (callback: () => void) => void;
}> = ({ onError, filters, onRefreshCallback }): React.ReactElement => {
  const history = useHistory();
  const onRowClick: GridEventListener<'rowClick'> = (task: GridRowParams): void => {
    history.push(reverse(routes.dashboard.alarms.devices.details, { deviceId: task.row.uuid }));
  };

  return (
    <Container>
      <Grid
        entity="device"
        onError={onError}
        disableColumnSelector
        temporaryFilters={filters}
        onRowClick={onRowClick}
        basicColumns={basicColumns}
        onRefreshCallback={onRefreshCallback}
        dataURL="/api/reports/usage/table/?type=device"
      />
    </Container>
  );
};

export default DeviceList;
