import styled from 'styled-components/macro';
import LinearProgress from '@mui/material/LinearProgress';

export const StyledLinearProgress = styled(LinearProgress).attrs({
  variant: 'determinate',
})`
  width: 90px;
  position: relative;
  .MuiLinearProgress-barColorPrimary {
    border-radius: 5px;
  }
`;

export const LicenseLabel = styled.p`
  color: rgba(0, 0, 0, 0.6);
  line-height: 143%;
  margin: 0;
  margin-left: 21px;
  letter-spacing: 0.17px;
`;
