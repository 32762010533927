import styled from 'styled-components/macro';
import IconButton from '@mui/material/IconButton';

import InfoIcon from '../../../../components/Icons/Info';

export const Title = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-items: center;
  display: flex;
  margin-bottom: 5px;
`;

export const MandatoryQuestion = styled.p`
  margin: 0;
  margin-left: 6px;
  color: rgba(211, 47, 47, 1);
`;

export const StyledIconButton = styled(IconButton)`
  padding: 5px;
  margin-left: 5px;
`;

export const StyledInfoIcon = styled(InfoIcon)`
  font-size: 18px;
`;
