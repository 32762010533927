import styled from 'styled-components/macro';

export const StyledCreated = styled.p`
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Container = styled.div`
  margin-top: 20px;
`;
