import { Button } from '@mui/material';

import styled from 'styled-components/macro';

import EmptyTasksListIcon from 'components/Icons/EmptyTasksListIcon';
import TaskStatusChip from 'containers/TaskDetails/components/TaskStatusChip';

export const TasksContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;

export const TasksSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  @media (max-width: 950px) {
    flex-basis: 100%;
  }
`;

export const TaskSummaryRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;

export const TaskSummaryCell = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background: #fff;
  gap: 10px;
`;

export const TasksSummaryHeader = styled.h4`
  color: rgba(0, 0, 0, 0.6);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin: 0px 0px 5px 0px;
`;

export const TasksSummaryContent = styled.h1`
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0.15px;
  margin: 0px;
`;

export const TasksListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
`;

export const TaskListCell = styled.div`
  display: flex;
  flex-direction: row;

  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
  padding: 16px;
  align-items: center;
  cursor: pointer;
  * {
    cursor: pointer;
  }
`;

export const TaskListCellStatusChip = styled(TaskStatusChip)`
  display: flex;
  align-items: center;
  max-height: 24px;
  font-size: 13px;
  line-height: 18px;
`;

export const TaskListCellTitle = styled.h3`
  display: flex;
  flex: 1;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.17px;
  margin: 0px 0px 0px 15px;
`;

export const EmptyTasksListContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
`;

export const EmptyTasksListMessage = styled.h3`
  display: flex:
  color: rgba(0, 0, 0, 0.60);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.15px;
`;

export const StyledEmptyTasksListIcon = styled(EmptyTasksListIcon)`
  width: 70px;
  height: 70px;
`;

export const CreateNewTaskButton = styled(Button)`
  font-size: 14px;
  color: #0772e5;
  font-weight: 500;
  font-family: Roboto;
  line-height: 24px;
  letter-spacing: 0.4px;
  padding: 6px 0px 6px 0px;
`;

export const EmptyTaskCellLeftSkeletonsContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 15px;
  align-items: center;
`;

export const EmptyTaskCellRightSkeletonsContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
