import styled from 'styled-components/macro';
import { Divider } from '@mui/material';

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 8px 0;
  align-items: center;
  overflow: hidden;
`;

export const StyledRowTitle = styled.p`
  color: #000;

  font-feature-settings: 'clig' off, 'liga' off;
  /* Typography/Overline */
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 266%; /* 31.92px */
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0;
  white-space: nowrap;
  padding-right: 10px;
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
`;
