import React from 'react';
import { observer } from 'mobx-react';
import { reverse } from 'named-urls';
import { UuidableName } from 'vatix-ui/lib/utils/api/types';
import { GridEventListener, GridRowParams } from '@mui/x-data-grid';

import { VatixError } from 'vatix-ui/lib/components/Error/types';

import { Tooltip } from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';

import { useStore } from 'utils/hooks/store';
import { UserListItemResponse } from 'utils/api/types';
import ResponseProfileCell from 'containers/Manage/components/ResponseProfileCell';
import routes from 'core/routes';
import Grid from 'components/Grid/Grid';
import { ColumnDefinition } from 'components/Grid/types';
import UserCell from 'containers/Users/components/UserCell';
import Search from 'components/Grid/operators/Search';
import API from 'utils/api';

import MonitoredDevicesCell from '../MonitoredDevicesCell';
import { ColumnHeader, InfoContainer, InfoDescription } from './styles';

const ManageList: React.FunctionComponent<{
  onRefreshCallback: (callback: () => void) => void;
  onError: (error: VatixError) => void;
}> = ({ onError, onRefreshCallback }): React.ReactElement => {
  const { routing } = useStore();
  const onRowClick: GridEventListener<'rowClick'> = ({ row: { uuid } }: GridRowParams): void => {
    routing.push(reverse(routes.dashboard.admin.users.details, { userId: uuid }));
  };

  const basicColumns = ([
    {
      field: 'name',
      headerName: 'Name',
      type: 'string',
      valueGetter: (data: { row: UserListItemResponse }): UuidableName => ({
        uuid: data.row.uuid,
        name: data.row.name,
      }),
      // eslint-disable-next-line react/no-unused-prop-types
      renderCell: ({ value }: { value: { name: string; uuid: string } }) => (
        <UserCell name={value.name} uuid={value.uuid} />
      ),
      nativeFilter: 'query',
      flex: 2,
    },
    {
      field: 'alarmResponseProfile',
      headerName: 'Alarm response profile',
      valueGetter: (data: { row: UserListItemResponse; colDef: ColumnDefinition }) => data.row,
      // eslint-disable-next-line react/no-unused-prop-types
      renderCell: ({ row, colDef }: { row: UserListItemResponse; colDef: ColumnDefinition }) => (
        <ResponseProfileCell rowData={row} actions={colDef.actions} />
      ),
      filterOperators: Search(API.searchAlarmResponseProfiles, API.loadEscalationProfileDetails, 'name'),
      nativeFilter: 'alarm_response_profiles',
      flex: 2,
      renderHeader: () => (
        <ColumnHeader>
          Alarm response profile
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'white',
                  boxShadow:
                    // eslint-disable-next-line max-len
                    '0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)',
                  borderRadius: '4px',
                },
              },
            }}
            title={
              <InfoContainer>
                <InfoDescription>
                  Vatix 24/7 Monitoring: if your subscription includes access to the Vatix Alarm Receiving Centre.
                  Self-Monitoring if you are monitoring alarms internally.
                </InfoDescription>
              </InfoContainer>
            }
            placement="bottom"
          >
            <InfoIcon fontSize="small" style={{ marginLeft: '10px', color: 'rgba(0, 0, 0, 0.26)' }} />
          </Tooltip>
        </ColumnHeader>
      ),
    },
    {
      field: 'licenses',
      headerName: 'Products',
      valueGetter: (data: { row: UserListItemResponse }) => data.row,
      // eslint-disable-next-line react/no-unused-prop-types
      renderCell: ({ row, colDef }: { row: UserListItemResponse; colDef: ColumnDefinition }) => (
        <MonitoredDevicesCell rowData={row} actions={colDef.actions} />
      ),
      filterable: false,
      flex: 4,

      renderHeader: () => (
        <ColumnHeader>
          Products
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'white',
                  boxShadow:
                    // eslint-disable-next-line max-len
                    '0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)',
                  borderRadius: '4px',
                },
              },
            }}
            title={
              <InfoContainer>
                <InfoDescription>
                  Use the &apos;Mobile App&apos; switch to assign a mobile app licence to a user. Click &apos;Add
                  Device&apos; to assign a safety device to a user.
                </InfoDescription>
              </InfoContainer>
            }
            placement="bottom"
          >
            <InfoIcon fontSize="small" style={{ marginLeft: '10px', color: 'rgba(0, 0, 0, 0.26)' }} />
          </Tooltip>
        </ColumnHeader>
      ),
    },
  ] as unknown) as ColumnDefinition[];

  return (
    <Grid
      basicColumns={basicColumns}
      entity="license"
      onError={onError}
      onRowClick={onRowClick}
      dataURL="/api/users/?licenses=protectorAlarms"
      onRefreshCallback={onRefreshCallback}
      sortBy={{ field: 'name', sort: 'asc' }}
      disableColumnSelector
    />
  );
};

export default observer(ManageList);
