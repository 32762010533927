import styled from 'styled-components/macro';

export const SubmissionActivityContainer = styled.div`
  @media (min-width: 680px) {
    padding: 0px 15%;
  }
`;

export const Container = styled.div`
  background: #fafafa;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: 20px;
`;

export const SubmissionActivityWrapper = styled.div`
  padding: 0 20px 20px 20px;
  background: var(--grey-50, #fafafa);
  border: 1px solid var(--light-other-divider, rgba(0, 0, 0, 0.12));
  border-radius: 0px 0px 8px 8px;
`;
