import { MenuItem, Menu } from '@mui/material';
import styled from 'styled-components/macro';

import { RichTextInput } from 'vatix-ui/lib/components/TextInput';

import { CancelButton } from 'vatix-ui/lib/components/Button';

export const EditTaskRowName = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 20px 0 5px 0;
`;

export const StyledMenuItem = styled(MenuItem)`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  width: 100%;
`;

export const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    width: 45%;
  }
`;

export const StyledTextInput = styled(RichTextInput)`
  p {
    margin: 0.5rem;
  }
`;

export const TypeButton = styled(CancelButton)`
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  justify-content: flex-start;

  &.MuiButton-sizeMedium {
    height: 56px;

    &:hover {
      background: white;
    }
  }

  &.MuiButton-text {
    font-weight: 400;
  }

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.5);
  }
`;
