import styled from 'styled-components/macro';

export const StyledTextInput = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.15px;
  margin: 0;
`;

export const AnswerText = styled.p`
  font: normal normal normal 14px/20px Roboto;
  letter-spacing: 0px;
  color: #101d31;
  margin: 0;
  padding: 8px 16px;
  background: rgba(211, 214, 217, 0.2);
  border-radius: 8px;
  width: 100%;
  min-height: 40px;
`;
