import styled from 'styled-components/macro';

export const RoundBox = styled.div<{ roundColor: string; hovered: boolean; borderColor: string }>`
  cursor: default;
  width: 44px;
  height: 44px;
  border-radius: 99px;
  background-color: ${(props) => (props.hovered ? props.borderColor : props.roundColor)};
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  transform: translate(-50%, -50%);
  position: absolute;
`;
