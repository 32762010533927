import styled from 'styled-components/macro';

import Dialog from '@mui/material/Dialog';

export const Modal = styled(Dialog)`
  .MuiPaper-root {
    padding: 16px 24px;
    border-radius: 8px;
    border: 0px solid #eee;
    background: #fff;
    box-shadow: 0px 8px 50px 0px rgba(7, 5, 26, 0.07);
  }
`;

export const CustomModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CustomModalActions = styled.div<{ $buttonsSpacing?: string }>`
  display: flex;
  justify-content: ${($props) => ($props.$buttonsSpacing ? $props.$buttonsSpacing : 'flex-end')};
  gap: 10px;
  margin-right: 4px;
  margin-top: 30px;
`;

export const StyledModalTitle = styled.h1`
color:  rgba(0, 0, 0, 0.87)
font-size: 20px;
font-weight: 500;
line-height: 160%;
letter-spacing: 0.15px;
`;

export const CustomModalDescription = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.15px;
  margin-top: 10.6px;
  margin-bottom: 16px;
`;
