import styled from 'styled-components/macro';

export const PopUpRedAlertsContainer = styled.div`
  position: fixed;
  bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  left: 220px;
  right: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 800px;
  z-index: 1;
`;
