import styled from 'styled-components/macro';
import Link from '@mui/material/Link';

export const TaskLink = styled(Link)`
  font: normal normal 14px/18px Roboto;
  color: #0772e5;
  text-decoration: none;
  cursor: pointer;
  margin-left: 4px;
`;
