import React from 'react';

import { SearachPopoverProps } from 'vatix-ui/lib/components/Chip/SearchPopover/types';

import Chip from 'components/Chip';
import Delete from 'components/Icons/Delete';
import {
  DeviceTypeContent,
  DeviceTypeIDText,
  DeviceTypeNameText,
} from 'containers/Devices/components/DeviceTypeCell/styles';

const AdditionalDeviceDropDownItem: SearachPopoverProps['ListItem'] = ({ item, onClick }) => (
  <Chip
    key={item.uuid}
    label={
      <DeviceTypeContent>
        <DeviceTypeNameText>{`${item.brand} ${item.model}`}</DeviceTypeNameText>
        <DeviceTypeIDText>({item.name})</DeviceTypeIDText>
      </DeviceTypeContent>
    }
    iconLeft={<img src={item.image} alt={`${item.brand} ${item.model}`} />}
    iconRight={<Delete />}
    onDelete={onClick}
    size="small"
    colors={{
      background: '#FFF',
    }}
  />
);

export default AdditionalDeviceDropDownItem;
