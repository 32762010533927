import styled from 'styled-components/macro';

export const DueDateParagraph = styled.p<{ color: string }>`
  color: ${(props) => props.color};
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  margin: 0px 10px 0px 0px;
`;
