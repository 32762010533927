import React from 'react';

import { SearachPopoverProps } from 'vatix-ui/lib/components/Chip/SearchPopover/types';

import {
  DeviceTypeContent,
  DeviceTypeIDText,
  DeviceTypeNameText,
} from 'containers/Devices/components/DeviceTypeCell/styles';

import Chip from 'components/Chip';

const DropDownItem: SearachPopoverProps['ListItem'] = ({ item, onClick }) => (
  <Chip
    key={item.uuid}
    label={
      <DeviceTypeContent>
        <DeviceTypeNameText>{`${item.brand} ${item.model}`}</DeviceTypeNameText>
        <DeviceTypeIDText>({item.name})</DeviceTypeIDText>
      </DeviceTypeContent>
    }
    iconLeft={<img src={item.image} alt={`${item.brand} ${item.model}`} />}
    onClick={() => {
      onClick?.(item);
    }}
    size="small"
    colors={{
      background: '#FFF',
    }}
  />
);

export default DropDownItem;
