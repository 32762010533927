import styled from 'styled-components/macro';

import { StyledTextField } from 'components/Input/styles';

export const Container = styled.div`
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  background: #fff;
`;

export const SectionHeader = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  margin-bottom: 5px;
`;

export const TextInput = styled(StyledTextField)`
  min-height: 38px;
  margin-bottom: 20px;
`;
