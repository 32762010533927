import styled from 'styled-components/macro';

export const Container = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  background: #fafafa;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 20px 24px;
`;

export const Title = styled.h1`
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.15px;
`;
