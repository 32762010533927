import { styled } from 'styled-components/macro';
import { Button } from 'vatix-ui/lib/components/Button';

export const EditDeviceRowName = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 20px 0 5px 0;
`;

export const DeviceDetailsBlock = styled.div`
  display: flex;
  flex-direction: row;

  border-radius: 8px;
  background: #f5f5f5;

  margin-top: 16px;
  padding: 30px;
`;

export const DeviceDetailsBlockItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const DeviceDetailsBlockItemTitle = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 143%;
  letter-spacing: 0.17px;
  margin-top: 0;
  margin-bottom: 6px;
`;

export const StyledName = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin: 0;
`;

export const StyledId = styled.p`
  color: rgba(0, 0, 0, 0.6);
  /* Typography/Body 1 */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin: 0;
  margin-left: 4px;
`;

export const StyledOption = styled.li`
  padding: 2px 16px;
  min-height: 36px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
`;

export const FormControlContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-top: 24px;
`;

export const CancelButton = styled(Button)`
  margin-right: 16px;
`;
