import styled from 'styled-components/macro';
import { MenuItem as MuiMenuItem } from '@mui/material';

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const MenuItem = styled(MuiMenuItem)`
  font-size: 14px;
`;
