import styled from 'styled-components/macro';

export const UploadContainer = styled.div<{ $dragActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: ${({ $dragActive }) => ($dragActive ? 'rgba(7, 114, 229, 0.04)' : '')};
  padding: 30px 0px;
  border-radius: 8px;
  border: 1px dashed #0772e5;
  h1 {
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    font-weight: 400;
  }
  p {
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    font-size: 12px;
    line-height: 166%;
    letter-spacing: 0.4px;
    max-width: 450px;
  }
`;

export const UploadButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  color: #0772e5;
  font-size: 16px;
  font-weight: 400;
`;
