import styled from 'styled-components/macro';

export const QuestionContainer = styled.div<{ selected: boolean; $withoutSection?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin: ${(props) => (props.$withoutSection ? '8px 0px 8px' : '20px 20px 16px')};
  padding-bottom: 20px;
  border-radius: 8px;
  background: #ffffff;
  border: ${(props) => (props.selected ? '1px solid #0772e5' : '')};
  box-shadow: ${(props) => (props.selected ? '0px 4px 50px 0px rgba(0, 0, 0, 0.25);' : '')};
`;

export const SectionContainer = styled.div`
  margin-bottom: 16px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
`;

export const SectionContainerHeader = styled.div`
  display: flex;
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #101d31;
    margin: 0;
    margin-left: 12px;
  }
`;

export const Row = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

export const SectionScoreContainer = styled.div<{ $bgColor?: string }>`
  border-radius: 5px;
  background: ${(props) => props.$bgColor ?? '#fff59d'};
  display: flex;
  padding: 3px 4px;
  align-items: center;
  height: 24px;
  color: #fff;
  p {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 138.462% */
    letter-spacing: 0.16px;
    margin: 0;
    padding: 0px 6px;
  }
`;
