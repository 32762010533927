import styled from 'styled-components/macro';

import { DeviceTypeContent } from 'containers/Devices/components/DeviceTypeCell/styles';
import VatixTheme from 'theme/vatix';

export const DeviceLabel = styled(DeviceTypeContent)`
  flex: 1;
`;

export const Container = styled.div<{ text?: boolean }>`
  display: flex;
  flex-direction: row;
  margin-top: -4px;
  color: ${VatixTheme.palette.text.secondary};
  font: 14px/16px Roboto;
  align-items: center;
`;

export const DevicesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  & > * {
    margin-top: 4px;
  }
  margin-left: 18px;
`;

export const InsufficientLicensesBanner = styled('p')`
  background: #f9cccc;
  border-radius: 4px;
  font: normal normal medium 14px/16px Roboto;
  letter-spacing: 0px;
  color: #101d31;
  padding: 8px 12px;
  width: 100%;
`;

export const LicenseManagementLink = styled('a')`
  color: ${VatixTheme.palette.text.secondary};
  font-weight: bold;
  text-decoration: underline;
  margin-left: 4px;
`;

export const AdditionalDevicesChipContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
