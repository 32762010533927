import React from 'react';
import { observer } from 'mobx-react';
import { reverse } from 'named-urls';
import { GridEventListener, GridRowParams } from '@mui/x-data-grid';

import { formatDateTime } from 'vatix-ui/lib/utils/formatters/time';

import { VatixError } from 'vatix-ui/lib/components/Error/types';

import routes from 'core/routes';
import { useStore } from 'utils/hooks/store';

import Grid, { defaultFormatters, defaultOperators, defaultRenderers } from 'components/Grid';
import { LinkedTaskType, IncidentResponse, JSONSchemaValue } from 'utils/api/types';

import { ColumnDefinition } from 'components/Grid/types';

import LinkedTasksCell from '../LinkedTasksCell';
import { StyledCreated } from './styles';

import ActionCell from '../ActionCell/ActionCell';

const basicColumns = ([
  {
    field: 'subject',
    headerName: 'Name',
    nativeFilter: true,
  },
  {
    field: 'Type',
    customColumn: true,
  },
  {
    field: 'creator',
    headerName: 'Reporter',
    filterOperators: defaultOperators.user(),
    renderCell: defaultRenderers.user,
    valueFormatter: defaultFormatters.user,
    nativeFilter: true,
  },
  {
    field: 'owner',
    headerName: 'Owner',
    filterOperators: defaultOperators.user(),
    renderCell: defaultRenderers.user,
    valueFormatter: defaultFormatters.user,
    nativeFilter: true,
  },
  {
    field: 'linkedTasks',
    headerName: 'Tasks',
    renderCell: ({ value }: { value: JSONSchemaValue<LinkedTaskType> }) => (
      <LinkedTasksCell linkedTasks={value?.value} />
    ),
    valueFormatter: ({ value }: { value: JSONSchemaValue<LinkedTaskType> }) =>
      `pending:${value?.value.pending};completed:${value?.value.completed};total:${value?.value.total}`,
    nativeFilter: true,
  },
  {
    field: 'Status',
    customColumn: true,
  },
  {
    field: 'created',
    filterOperators: defaultOperators.date(),
    headerName: 'Created',
    renderCell: ({ value }: { value: JSONSchemaValue<string> }) => (
      <StyledCreated>{formatDateTime(value?.value)}</StyledCreated>
    ),
    sortable: true,
    nativeFilter: true,
    valueFormatter: defaultFormatters.date,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    maxWidth: 50,
    ariaLabel: 'actions_cell',
    renderCell: ({ row, colDef }: { row: IncidentResponse; colDef: ColumnDefinition }) => (
      <ActionCell rowData={row} actions={colDef.actions} />
    ),
    filterOperators: null,
  },
] as unknown) as ColumnDefinition[];

const IncidentList: React.FunctionComponent<{ onError: (error: VatixError) => void }> = ({ onError }) => {
  const { routing } = useStore();
  const onRowClick: GridEventListener<'rowClick'> = (incident: GridRowParams): void => {
    routing.push(reverse(routes.dashboard.incidents.details, { incidentId: incident.row.uuid }));
  };

  return (
    <Grid
      basicColumns={basicColumns}
      onError={onError}
      onRowClick={onRowClick}
      dataURL="/api/incidents/?type=custom"
      fieldsURL="/api/incidents/fields/"
      sortBy={{ field: 'created', sort: 'desc' }}
      disableSelectAllColumns
    />
  );
};

export default observer(IncidentList);
