import { Button } from '@mui/material';
import styled from 'styled-components/macro';

import MainHeader from 'vatix-ui/lib/components/MainHeader';

export const HomeHeader = styled(MainHeader)`
  font-size: 32px;
`;

export const HomeSectionHeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const HomeSectionHeader = styled.h2`
  display: flex;
  flex: 1;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
`;

export const ViewAllButtonLink = styled.a`
  text-decoration: none;
`;

export const HomeSectionHeaderButton = styled(Button)`
  display: flex;
  max-height: 36px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.4px;
  border-radius: 5px;
  background: #e0e0e0;
  padding: 6px 16px 6px 16px;
  text-transform: none;
  &:hover {
    background-color: #ececed;
  }
`;
