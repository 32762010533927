import styled from 'styled-components/macro';

export const BaseLayout = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 5px;
  background: #fff;
  overflow: auto;
`;

export const SectionHeader = styled.h1`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 6px;
`;

export const AlarmRespondersHeader = styled.p`
  color: #101d31;
  font-size: 20px;
  font-weight: 500;
  line-height: 160%; /* 32px */
  margin: 20px 0;
  letter-spacing: 0.15px;
`;

// common styles for escalations row
export const EscalationContainer = styled.div`
  display: flex;
  flex-direction: row;
  display: flex;
  padding: 20px 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 5px;
  background: #fafafa;
  margin-bottom: 20px;
`;

export const EscalationOrder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  height: 32px;
  color: #fff;
  background-color: rgba(189, 189, 189, 1);
  border-radius: 16px;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.14px;
  margin-right: 10px;
`;

export const EscalationCell = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  justify-content: bottom;
  margin-right: 10px;
  width: ${(props) => props.width};
`;

export const CellHeader = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 8px;
`;
