import styled from 'styled-components/macro';

import { Button } from '@mui/material';

import { Link } from 'react-router-dom';

export const HelpButton = styled.a`
  font: 14px/16px Roboto;
  letter-spacing: 0px;
  color: #0772e5;
  padding-bottom: 12px;
  text-decoration: none;
  cursor: pointer;
`;

export const HelpLink = styled(Link)`
  display: flex;
  font: 14px/16px Roboto;
  letter-spacing: 0px;
  color: #0772e5;
  text-decoration: none;
  margin-top: 12px;
`;

export const LoginPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(to bottom right, #053468, #086ad4);
`;

export const LoginPageContainer = styled.div`
  border-radius: 16px;
  display: flex;
  padding: 32px;
  justify-content: space-between;
  min-width: 480px;
  max-width: 500px;
  background-color: white;
`;

export const FormContainer = styled.div`
  flex: 1;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  font-style: normal;
  margin-bottom: 24px;
`;

export const InputTitle = styled.h1`
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin: 0;
`;

export const EmailTitle = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.17px;
`;

export const InputSubtitle = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.15px;
  white-space: pre-line;
`;

export const StyledButton = styled(Button)`
  border-radius: 10px;
  height: 50px;
  width: 100%;
  font-size: 14px;
  margin-top: 20px;
`;

export const BackButton = styled(Button)`
  color: #0772e5;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-transform: none;
  background-color: inherit;
  font-size: 14px;
  padding-right: 10px;
`;
