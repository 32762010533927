import styled from 'styled-components/macro';

export const Container = styled.div<{
  $noMargins?: boolean;
  $setMaxWidth?: number;
}>`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: ${({ $noMargins }) => ($noMargins ? '0' : '4px 0')};
  width: 100%;
  p {
    max-width: ${({ $setMaxWidth }) => `${$setMaxWidth}px` || 'none'};
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Item = styled.div`
  margin: 6px 16px;
  p {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 143%;
    letter-spacing: 0.17px;
    margin: 0;
    margin-left: 8px;
  }
`;

export const PlusChipContainer = styled.div`
  margin-left: 8px;
`;

export const EmptyText = styled.p`
  color: rgba(0, 0, 0, 0.6);
`;

export const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0px;
  overflow-y: auto;
`;
