import styled from 'styled-components/macro';

import { DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';
import MenuItem from '@mui/material/MenuItem';

import VatixTheme from 'theme/vatix';

export const getItemContainerStyle = (
  isDragging: boolean,
  draggableStyle?: DraggingStyle | NotDraggingStyle
): object => ({
  margin: `8px auto`,
  backgroundColor: 'rgba(109, 120, 131,0.05)',
  borderRadius: '6px',
  paddingTop: '10px',
  width: 'calc(100% - 48px)',
  boxShadow: isDragging ? '-4px 0px 0px #0772E5' : '',
  ...draggableStyle,
});

export const getSectionContainerStyle = (
  isDragging: boolean,
  draggableStyle?: DraggingStyle | NotDraggingStyle
): object => ({
  margin: `8px auto`,
  paddingTop: '10px',
  backgroundColor: isDragging ? '#E1F5FE' : '',
  paddingBottom: '10px',
  width: '100%',
  ...draggableStyle,
});

export const TemplateNameLabel = styled.h1`
  color: ${VatixTheme.palette.text.secondary};
  font-weight: normal;
  margin: 0;
  margin-top: 16px;
`;

export const QuestionRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  align-items: flex-start;
  margin-left: 24px;
  width: calc(100% - 48px);
`;

export const NonQuestionRow = styled(QuestionRow)`
  margin-left: 24px;
  width: calc(100% - 48px);
`;

export const QuestionsLeftSide = styled.div`
  align-items: center;
  display: flex;
  width: 60%;
`;

export const QuestionsRightSide = styled.div`
  display: flex;
  margin-left: 4px;
  width: 40%;
  flex-direction: column;
  align-items: flex-end;
`;

export const TitleRightSide = styled(QuestionsRightSide)`
  align-items: flex-start;
  margin-left: -16px;
`;

export const BaseLayout = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background: #fff;
`;

export const Layout = styled.div`
  max-width: 1280px;
  width: 100%;
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 1.4rem;
`;

export const AddText = styled.p`
  color: #0772e5;
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.46px;
  margin: 0;
  margin-left: 8px;
`;
