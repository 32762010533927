import React from 'react';

import { GridFilterOperator, useGridApiContext } from '@mui/x-data-grid';
import { GridFilterItem } from '@mui/x-data-grid/models/gridFilterItem';

import { escapeRegExp } from 'lodash';

import CustomUserDropdownField from 'containers/IncidentDetails/components/CustomUserDropdownField';

import api from 'utils/api';

import { PickerProps } from '../types';

const UserDropdown: React.FunctionComponent<PickerProps> = ({ applyValue, item, ...props }) => (
  <CustomUserDropdownField
    description="User"
    {...props}
    onChange={(newValue) => applyValue({ ...item, value: ((newValue || '') as string).replace('user:', '') })}
    value={(item.value as string) || null}
  />
);

const cache = new Map<string, string>();

export default (): GridFilterOperator[] =>
  ([
    {
      label: 'is',
      value: 'is',
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.value) {
          return null;
        }
        const filterRegex = new RegExp(escapeRegExp(filterItem.value.trim()), 'i');
        return ({ value }: { value: string }): boolean => (value != null ? filterRegex.test(value.toString()) : false);
      },
      InputComponent: UserDropdown,
      InputComponentProps: { value: null },
      getValueAsString(value: string) {
        const context = useGridApiContext();
        (async () => {
          if (cache.has(value)) {
            return;
          }
          const { data } = await api.loadUserDetails(value)();
          cache.set(value, data.name);
          context.current.setFilterModel({ ...context.current.state.filter.filterModel });
        })();

        return cache.get(value) || 'loading...';
      },
    },
  ] as unknown) as GridFilterOperator[];
