import styled from 'styled-components/macro';

export const NotificationBarContainer = styled.div`
  border-radius: 4px;
  background: #fbeaea;
  font-size: 1.8rem;
  line-height: 2.1rem;
  height: 50px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  z-index: 100;
  margin: 0 20px;
  margin-top: 20px;
`;

export const NotificationBarMessage = styled.p`
  flex-grow: 1;
  color: #d32f2f;
  margin-left: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: 0.15px;
`;
