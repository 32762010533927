import styled from 'styled-components/macro';

import VatixTheme from 'theme/vatix';

import Phone from '../Icons/Phone';

export const AlarmSourceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const AlarmSourceModel = styled.p`
  color: ${VatixTheme.palette.text.secondary};
  text-align: left;
  font-size: 16px;
  letter-spacing: 0;
  margin: 0;
  margin-left: 5px;
  padding-right: 2px;
`;

export const AlarmSourceId = styled.p`
  color: ${VatixTheme.palette.text.secondary};
  text-align: left;
  font-size: 16px;
  letter-spacing: 0;
  margin: 0;
  margin-left: 5px;
`;

export const AlarmSourceIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const StyledPhone = styled(Phone)`
  color: black;
  margin-right: 4px;
`;
