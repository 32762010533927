import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  align-items: center;

  *:not(:first-child) {
    margin-left: 4px;
    margin-right: 4px;
  }
`;
