import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;

  &:not(:first-child) {
    margin-left: 8px;
  }
`;
