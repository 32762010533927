import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import { NotFound } from 'vatix-ui/lib/components/Error/Pages';

import { UserRole } from 'core/constants';
import routes from 'core/routes';
import { SessionStoreWithUser } from 'stores/Session/types';

import UserRoute from 'components/Route/UserRoute';
import Incidents from 'containers/Incidents';
import ActivityMap from 'containers/ActivityMap';
import Devices from 'containers/Devices';
import UserDetails from 'containers/UserDetails';
import DeviceDetails from 'containers/DeviceDetails';
import Users from 'containers/Users';
import AlarmsConfigure from 'containers/AlarmsConfigure';
import IncidentDetails from 'containers/IncidentDetails';
import AlarmDetails from 'containers/AlarmDetails';
import Manage from 'containers/Manage';
import Analytics from 'containers/Analytics';

import UsageReport from 'containers/Reports/components/UsageReport';

import TaskDetails from 'containers/TaskDetails';

import Tasks from 'containers/Tasks';
import MyTasks from 'containers/Tasks/components/MyTasks';
import ReportIncident from 'containers/Incidents/components/ReportIncident';

import Notifications from 'containers/Notifications';

import DisableFeatures from 'containers/DisableFeatures/DisableFeatures';

import { AmberAlarms, RedAlarms } from 'containers/Alarms/Alarms';

import Teams from 'containers/Admin/Teams';

import MainAnalytics from 'containers/Analytics/components/MainAnalytics';
import Home from 'containers/Home';

import Licenses from 'containers/Licenses';

import TeamDetails from 'containers/Admin/TeamDetails';

import ProfileSecuritySettings from 'containers/ProfileSecuritySettings';
import ProfileNotificationsSettings from 'containers/ProfileNotificationsSettings';
import NotificationSettingsStore from 'stores/NotificationSettings/NotificationSettings';

import Forms from 'containers/Forms';

import FormsDetails from 'containers/FormsDetails';

import FormTemplates from 'containers/FormTemplates';

import CompanySettings from 'containers/CompanySettings';

import MoreApps from 'containers/MoreApps';

import TemplateDetails from 'containers/TemplateDetails';

import { ADMIN_AND_MANAGERS, InjectedRoutingProps, NON_ARC_OPERATORS, NON_USER } from './types';
import { RoutingContainer } from './styles';

const Routing = ({ session, isDetailsView }: InjectedRoutingProps): React.ReactElement => (
  <RoutingContainer $isDetailsView={isDetailsView}>
    <Switch>
      {/* Home */}
      <Route path={String(routes.dashboard.home)} component={Home} exact data-test-id="dashboard-redirect" />

      {/* Inbox */}
      <UserRoute
        path={String(routes.dashboard.notifications)}
        component={Notifications}
        isAuthorizedUser={() => session.user.licenses.protectorIncidents || session.user.licenses.workflows}
        exact
      />

      {/* Actions */}
      <UserRoute
        path={String(routes.dashboard.tasks.all)}
        component={Tasks}
        isAuthorizedUser={(user) => NON_ARC_OPERATORS.includes(user.role)}
        exact
      />
      <UserRoute
        path={String(routes.dashboard.tasks.my)}
        component={MyTasks}
        isAuthorizedUser={(user) => NON_ARC_OPERATORS.includes(user.role)}
        exact
      />
      <UserRoute
        path={routes.dashboard.tasks.details}
        component={TaskDetails}
        isAuthorizedUser={(user) =>
          NON_ARC_OPERATORS.includes(user.role) &&
          (session.user.licenses.protectorIncidents || session.user.licenses.workflows)
        }
        exact
      />

      {/* Incidents */}
      <UserRoute
        path={routes.dashboard.incidents.report}
        component={ReportIncident}
        isAuthorizedUser={(user) => NON_ARC_OPERATORS.includes(user.role) && session.user.licenses.protectorIncidents}
        exact
      />
      <UserRoute
        path={String(routes.dashboard.incidents)}
        component={Incidents}
        isAuthorizedUser={(user) => NON_ARC_OPERATORS.includes(user.role) && session.user.licenses.protectorIncidents}
        onUnauthorizedRedirectProducts
        exact
      />
      <UserRoute
        path={routes.dashboard.incidents.details}
        component={IncidentDetails}
        isAuthorizedUser={(user) => NON_ARC_OPERATORS.includes(user.role) && session.user.licenses.protectorIncidents}
        exact
      />

      {/* Lone working */}
      <UserRoute
        path={String(routes.dashboard.alarms.escalationProfiles)}
        component={AlarmsConfigure}
        isAuthorizedUser={(user) => [UserRole.Admin, UserRole.AccountManager, UserRole.Manager].includes(user.role)}
      />
      <UserRoute
        path={routes.dashboard.alarms.activityMap}
        component={ActivityMap}
        exact
        isAuthorizedUser={(user) =>
          user.licenses.protectorAlarms &&
          (user.organisation.activityMap || [UserRole.ArcOperator, UserRole.Admin].includes(user.role))
        }
        onUnauthorizedRedirectProducts
      />
      <UserRoute path={String(routes.dashboard.alarms)} isAuthorizedUser={(user) => NON_USER.includes(user.role)} exact>
        <Redirect to={routes.dashboard.alarms.red} exact />
      </UserRoute>
      <UserRoute
        path={String(routes.dashboard.alarms.red)}
        component={RedAlarms}
        isAuthorizedUser={(user) => NON_USER.includes(user.role)}
        exact
      />
      <UserRoute
        path={String(routes.dashboard.alarms.amber)}
        component={AmberAlarms}
        isAuthorizedUser={(user) => NON_USER.includes(user.role)}
        exact
      />
      <UserRoute
        path={String(routes.dashboard.alarms.manage)}
        component={Manage}
        isAuthorizedUser={(user) => ADMIN_AND_MANAGERS.includes(user.role)}
        exact
      />
      <UserRoute
        path={String(routes.dashboard.alarms.devices)}
        component={Devices}
        isAuthorizedUser={(user) => ADMIN_AND_MANAGERS.includes(user.role)}
        exact
      />
      <UserRoute
        path={routes.dashboard.alarms.details}
        component={AlarmDetails}
        isAuthorizedUser={(user) => NON_USER.includes(user.role)}
        exact
      />
      <UserRoute
        path={routes.dashboard.alarms.devices.details}
        component={DeviceDetails}
        isAuthorizedUser={(user) => ADMIN_AND_MANAGERS.includes(user.role)}
        exact
      />
      <Route
        path={String(routes.dashboard.alarms.reports)}
        component={UsageReport}
        data-test-id="reports-device-route"
        exact
      />

      {/* Audits */}

      <UserRoute path={String(routes.dashboard.audits)} isAuthorizedUser={(user) => NON_USER.includes(user.role)} exact>
        <Redirect to={routes.dashboard.audits.forms.toString()} exact />
      </UserRoute>
      <UserRoute
        path={String(routes.dashboard.audits.forms)}
        component={Forms}
        data-test-id="forms-route"
        exact
        isAuthorizedUser={(user) => user.licenses.workflows}
      />
      <UserRoute
        path={String(routes.dashboard.audits.forms.details)}
        component={FormsDetails}
        data-test-id="forms-details-route"
        exact
        isAuthorizedUser={(user) => user.licenses.workflows}
      />
      <UserRoute
        path={String(routes.dashboard.audits.templates)}
        component={FormTemplates}
        data-test-id="forms-template-route"
        exact
        isAuthorizedUser={(user) => user.licenses.workflows}
      />
      <UserRoute
        path={String(routes.dashboard.audits.templates.details)}
        component={TemplateDetails}
        isAuthorizedUser={(user) => user.licenses.workflows}
        exact
      />

      {/* Admin */}

      <UserRoute
        path={String(routes.dashboard.admin.companySettings)}
        component={CompanySettings}
        isAuthorizedUser={(user) => [UserRole.Admin, UserRole.AccountManager].includes(user.role)}
        exact
      />

      <UserRoute
        path={String(routes.dashboard.admin.users)}
        component={Users}
        isAuthorizedUser={(user) => [UserRole.Admin, UserRole.AccountManager].includes(user.role)}
        exact
      />

      <UserRoute
        path={routes.dashboard.admin.users.details}
        isAuthorizedUser={(user) =>
          [UserRole.Admin, UserRole.Manager, UserRole.User, UserRole.ArcOperator, UserRole.AccountManager].includes(
            user.role
          )
        }
        component={UserDetails}
        exact
      />
      <UserRoute
        path={routes.dashboard.admin.licenses}
        component={Licenses}
        isAuthorizedUser={(user) => [UserRole.Admin, UserRole.AccountManager].includes(user.role)}
        exact
      />

      <UserRoute
        path={String(routes.dashboard.admin.teams)}
        component={Teams}
        data-test-id="teams-route"
        exact
        isAuthorizedUser={(user) => [UserRole.Admin, UserRole.AccountManager].includes(user.role)}
      />

      <Route path={String(routes.dashboard.admin.teams.new)} component={TeamDetails} data-test-id="teams-route" exact />

      <Route
        path={String(routes.dashboard.admin.teams.details)}
        component={TeamDetails}
        data-test-id="teams-route"
        exact
      />

      {/* ============ */}
      <UserRoute
        path={routes.dashboard.disableFeature.details.toString()}
        component={DisableFeatures}
        isAuthorizedUser={(user) => NON_ARC_OPERATORS.includes(user.role)}
        exact
      />

      <UserRoute
        path={String(routes.dashboard.analytics)}
        component={MainAnalytics}
        isAuthorizedUser={(user) => ADMIN_AND_MANAGERS.includes(user.role)}
        exact
      />

      <UserRoute
        path={routes.dashboard.analytics.details}
        component={Analytics}
        isAuthorizedUser={(user) => ADMIN_AND_MANAGERS.includes(user.role)}
        exact
      />

      <UserRoute
        path={routes.dashboard.profileSettings.notifications.toString()}
        component={ProfileNotificationsSettings}
        isAuthorizedUser={(user) => user.licenses.protectorIncidents || user.licenses.workflows}
        exact
      />

      <UserRoute
        path={routes.dashboard.profileSettings.security.toString()}
        component={ProfileSecuritySettings}
        isAuthorizedUser={(user) => user !== undefined && user !== null}
        exact
      />

      <UserRoute
        path={String(routes.dashboard.moreApps)}
        component={MoreApps}
        data-test-id="more-apps"
        exact
        isAuthorizedUser={(user) => user !== undefined && user !== null}
      />

      <Route component={NotFound} data-test-id="dashboard-not-found" />
    </Switch>
  </RoutingContainer>
);

Routing.defaultProps = {
  session: (null as unknown) as SessionStoreWithUser,
  notificationSettings: (null as unknown) as NotificationSettingsStore,
};

export default inject('session')(observer(Routing));
