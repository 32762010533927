import styled from 'styled-components/macro';

import { Link } from '@mui/material';
import Details from 'vatix-ui/lib/components/Details';

export const StyledDetails = styled(Details)`
  margin-bottom: 30px;
`;

export const PropertyValue = styled.p`
  color: #101d31;
  font: normal normal normal 14px/20px Roboto;
  margin: 0;
  margin-top: 8px;
  word-break: break-word;
  white-space: normal;
  padding-right: 1rem;
`;

export const MoreLink = styled(Link)`
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  text-decoration-style: dotted;

  :hover {
    cursor: pointer;
  }
`;

export const RowValue = styled.p`
  text-align: left;
  margin: 0;
  opacity: 1;
  white-space: pre-line;
  word-break: break-word;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
`;
