import styled from 'styled-components/macro';

import Avatar from 'vatix-ui/lib/components/Avatar/Avatar';

export const Container = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  margin-right: 2px;

  &:not(:first-child) {
    margin-left: 8px;
  }
`;

export const NotAnswered = styled.p`
  margin: 0;
  color: #00000099;
`;

export const UserCellName = styled.p`
  text-decoration: none;
  margin: 0;
  margin-left: 8px;
  font-size: 14px;
  line-height: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const UserCellAvatar = styled(Avatar)`
  line-height: 16px;
`;

export const UserCellNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
`;
