import { Button } from '@mui/material';
import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Cell = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  border: 1px solid var(--light-other-divider, rgba(0, 0, 0, 0.12));
  background: #fff;
  margin-top: 16px
  margin-bottom: 16px;
`;

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const SettingTitle = styled.h3`
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin-bottom: 4px;
  margin-left: 20px;
`;

export const SettingsDescription = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin-left: 20px;
  margin-top: 0px;
`;

export const DisabledSettingsDescription = styled(SettingsDescription)`
  color: rgba(0, 0, 0, 0.38);
`;

export const ChangePasswordButton = styled(Button).attrs({
  size: 'medium',
  color: 'primary',
  variant: 'outlined',
})`
  margin: 24px 20px 24px 32px;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-transform: capitalize;
`;

export const Enable2FAButtonWrapper = styled.span`
  align-self: flex-start;
`;

export const Enable2FAButton = styled(ChangePasswordButton)`
  align-self: flex-start;
`;
