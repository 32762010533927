import styled from 'styled-components/macro';

import { Divider, Grid } from '@mui/material';

import { Value } from '../EditableTextInput/styles';

export const BackgroundInformationSections = styled.div`
  position: relative;
  overflow-y: scroll;
  margin: 15px 20px 0px;
  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledRowTitle = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 600;
  line-height: 143%;
  letter-spacing: 0.17px;
  margin: 0;
  overflow-wrap: anywhere;
`;

export const RowWrapper = styled.div`
  width: 100%;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
`;

export const StyledDivider = styled(Divider)`
  margin-top: 7px;
`;

export const StyledName = styled(Value)`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
`;

export const DescriptionField = styled.p`
  font-size: 16px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  word-break: break-word;
  white-space: pre-line;
  margin: 0;
  padding: 0;
  p {
    margin: 0;
    padding: 0;
  }
`;

export const LocationText = styled.p`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #0772e5;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex: 1;
  background: #ffffff;
  padding: 10px 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const SectionHeader = styled(Grid)`
  display: flex;
  justify-content: center;
  padding: 0px;
  background: rgba(7, 114, 229, 0.04);
  border-radius: 5px;
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;
    color: #0772e5;
    margin: 8px;
  }
`;
