import styled from 'styled-components/macro';

import React from 'react';

import DueDate from 'components/DueDate';

const StyledDueDate = styled(DueDate)`
  font-size: 16px;
`;

const TaskDueDate: React.FC<{ date: string }> = ({ date }): React.ReactElement => <StyledDueDate date={date} />;

export default TaskDueDate;
