import React from 'react';
import { observer } from 'mobx-react';
import { reverse } from 'named-urls';
import { GridEventListener, GridRowParams } from '@mui/x-data-grid';

import { VatixError } from 'vatix-ui/lib/components/Error/types';

import routes from 'core/routes';
import { useStore } from 'utils/hooks/store';

import Grid, { defaultFormatters, defaultOperators, defaultRenderers } from 'components/Grid';

import { ColumnDefinition } from 'components/Grid/types';

import { listRenderer } from 'components/Grid/renderers/GridListRenderer';

import { TeamResponse } from 'utils/api/types';
import { ListRendererType } from 'components/Grid/renderers/GridListRenderer/types';

import ActionCell from '../ActionCell/ActionCell';

const basicColumns = ([
  {
    field: 'name',
    headerName: 'Name',
    sortable: true,
    nativeFilter: true,
    flex: 1,
  },
  {
    field: 'admins',
    headerName: 'Admins',
    filterOperators: defaultOperators.user(),
    renderCell: listRenderer(defaultRenderers.user as ListRendererType, 3),
    valueFormatter: defaultFormatters.user,
    nativeFilter: true,
    flex: 4,
  },
  {
    field: 'numUsers',
    headerName: 'Members',
    filterOperators: defaultOperators.user(),
    valueFormatter: defaultFormatters.user,
    nativeFilter: 'users',
    sortable: true,
    flex: 1,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    maxWidth: 50,
    ariaLabel: 'test',
    renderCell: ({ row, colDef }: { row: TeamResponse; colDef: ColumnDefinition }) => (
      <ActionCell rowData={row} actions={colDef.actions} />
    ),
  },
] as unknown) as ColumnDefinition[];

const TeamList: React.FunctionComponent<{ onError: (error: VatixError) => void }> = ({ onError }) => {
  const { routing } = useStore();
  const onRowClick: GridEventListener<'rowClick'> = (team: GridRowParams): void => {
    routing.push(reverse(routes.dashboard.admin.teams.details, { teamId: team.row.uuid }));
  };

  return (
    <Grid
      basicColumns={basicColumns}
      onError={onError}
      onRowClick={onRowClick}
      dataURL={`${process.env.REACT_APP_ADMIN_API_URL}teams/`}
      sortBy={{ field: 'name', sort: 'asc' }}
      disableColumnSelector
    />
  );
};

export default observer(TeamList);
