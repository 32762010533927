import styled from 'styled-components/macro';

import { columnFlex } from 'core/styles';

export const Container = styled.div`
  display: flex;
`;

export const Content = styled.main`
  ${columnFlex};
  padding: 0;
  background-color: #f3f4f5;
  min-height: 100vh;
  min-width: 0;
`;
