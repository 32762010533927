import { MenuItem, Select } from '@mui/material';

import styled from 'styled-components/macro';

import VatixTheme from 'theme/vatix';

export const StyledSelect = styled(Select)`
  appearance: none;
  border: 1px solid ${VatixTheme.palette.grey[400]};
  border-radius: 8px;
  color: ${VatixTheme.palette.text.secondary};
  height: 40px;
  outline: none;
  padding: 10px 0;
  font-size: 1.4rem;
  text-align: left;
  line-height: 1.5rem;
  color: ${VatixTheme.palette.secondary[100]};
`;

export const StyledMenuItem = styled(MenuItem)`
  color: ${VatixTheme.palette.grey[200]};
  font-size: 1.3rem;
  line-height: 2rem;
`;
