import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components/macro';

import { StyledTheme } from '../../../../theme';

const StyleProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }): React.ReactElement => (
  <StyledThemeProvider theme={StyledTheme}>{children}</StyledThemeProvider>
);

export default StyleProvider;
