import { DataGridPro } from '@mui/x-data-grid-pro';
import styled from 'styled-components/macro';

export const CardButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`;

export const CardButton = styled.div`
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background: #fff;
  display: flex;
  padding: 20px;
  align-items: center;
  flex: 1;
  cursor: pointer;
`;

export const CardButtonText = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
  text-decoration: none;
  margin: 0;
  margin-left: 5px;
`;

export const DataGridContainer = styled.div`
  background: #fff;
  border: 1px solid #e2e4e6;
  border-radius: 4px;
`;

export const StyledDataGrid = styled(DataGridPro)`
  & .MuiDataGrid-columnHeaderTitle {
    font-weight: 500;
  }

  & .MuiDataGrid-cell:first-child,
  & .MuiDataGrid-columnHeader:first-child {
    padding-left: 20px;
  }

  & .MuiDataGrid-cell:last-child,
  & .MuiDataGrid-columnHeader:last-child {
    padding-right: 20px;
  }

  & .MuiDataGrid-cellContent {
    white-space: nowrap;
  }

  & {
    font-size: 14px;
    border-radius: 4px 4px 4px 4px;
    border: none;
    background: #ffffff;
  }

  & .MuiTablePagination-selectLabel,
  & .MuiTablePagination-displayedRows {
    font-size: 14px;
  }

  & .MuiTablePagination-select {
    display: flex;
    align-content: center;
    align-items: center;
  }

  .MuiButton-root {
    color: #4ba0fd;
    text-transform: capitalize;
  }

  & .MuiButton-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 143%; /* 20.02px */
    letter-spacing: 0.17px;
  }

  & .MuiButton-startIcon {
    color: #4ba0fd;
  }

  & .MuiSvgIcon-fontSizeMedium {
    font-size: 24px;
  }

  .MuiDataGrid-footerContainer {
    max-height: 48px;
    border-radius: 0 0 4px 4px;
    border-bottom: 0;
  }

  & .MuiDataGrid-cell:focus-within,
  & .MuiDataGrid-cell:focus {
    outline: none;
  }

  --DataGrid-overlayHeight: 52px;

  .MuiDataGrid-overlayWrapper {
    min-height: 52px;
  }
`;
