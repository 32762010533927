import styled from 'styled-components/macro';

export const StyledRowTitle = styled.p`
  margin-right: 14px;
  margin-top: 0;
  margin-bottom: 6px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 600;
  line-height: 143%;
  letter-spacing: 0.17px;
`;
