import styled from 'styled-components/macro';

import { center } from 'core/styles';

import VatixTheme from 'theme/vatix';

export const DeviceTypeImageContainer = styled.div`
  ${center};
  margin: 4px;

  img {
    max-height: 24px;
    max-width: 24px;
    object-fit: contain;
  }

  pointer-events: none;
  * {
    pointer-events: none;
  }
`;

export const DeviceTypeWrapper = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: start;
`;

export const DeviceTypeContent = styled.div`
  display: flex;
  margin-left: 6px;
  margin-right: 6px;
`;

const DeviceTypeText = styled.div`
  color: ${VatixTheme.palette.text.secondary};
  font: normal normal normal 14px/16px Roboto;
  line-height: 24px;
`;

export const DeviceTypeNameText = styled(DeviceTypeText)`
  margin-right: 4px;
`;

export const DeviceTypeIDText = styled(DeviceTypeText)`
  color: ${VatixTheme.palette.text.hint};
`;
