import { Button } from '@mui/material';
import { Field } from 'formik';
import styled from 'styled-components/macro';

export const FormRow = styled.div<{
  hasError: boolean;
}>`
  display: flex;
  flex-direction: column;
  .MuiFormHelperText-root {
    margin-top: 0px;
    margin-left: 12px;
    color: #d32f2f;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.4px;
  }
  .MuiFormControl-fullWidth {
    margin-bottom: 3px;
  }
  margin-bottom: ${(props) => (props.hasError ? 16 : 0)}px;
`;

export const RowLabel = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0px;
  margin-bottom: 5px;
`;

export const StyledField = styled(Field)`
  margin-bottom: 4px;
  input {
    padding: 16px 12px 16px 12px;
  }
  svg {
    display: none;
  }
`;

export const FormStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 16px 6px 16px;
  border-radius: 4px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 100%), #d32f2f;
  transition: all 0.2s;
`;

export const FormStatus = styled.p`
  color: rgba(211, 47, 47, 1);

  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  margin-left: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const InnerFormStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0px;
`;

export const FormFooter = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  margin-top: 16px;
  gap: 16px;
`;

export const SubmitButton = styled(Button).attrs({
  type: 'submit',
  size: 'large',
  color: 'primary',
  variant: 'contained',
})`
  height: 42px;
  padding: 7px 21px 7px 21px;
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.46px;
  text-transform: none;
  box-shadow: none;
`;
