import styled from 'styled-components/macro';

import Grid from '@mui/material/Grid';

export const FieldDescription = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  margin: 16px 0 5px;
`;

export const SectionGrid = styled(Grid).attrs({
  classes: {
    item: 'grid-item',
  },
})`
  &.grid-item {
    align-items: center;
    padding-bottom: 0;
    padding-top: 0;
  }
`;
