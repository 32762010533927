import styled from 'styled-components/macro';

import { SectionGrid } from 'components/BackgroundInformation/styles';

export const StyledSectionGrid = styled(SectionGrid)`
  min-height: 24px;
`;

export const StyledRowTitle = styled.p<{ $disabled: boolean }>`
  color: ${(props) => (props.$disabled ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0, 0.87)')};
  font-size: 14px;
  font-weight: 600;
  line-height: 143%;
  letter-spacing: 0.17px;
  margin: 0;
`;

export const ButtonWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  button {
    margin-left: 16px;
  }
`;

export const StyledOption = styled.li`
  padding: 2px 16px;
  min-height: 36px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
`;
