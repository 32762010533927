import styled from 'styled-components/macro';

export const LocationsContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
  z-index: 1;
  background-color: white;
  width: 100%;
`;

export const LocationItem = styled.div`
  padding: 8px 16px;
  cursor: pointer;
  margin: 4px 0;
  display: flex;

  :hover {
    background-color: #f6f7f7;
  }
`;

export const LocationText = styled.p`
  font: normal normal normal 14px/16px Roboto;
  color: #101d31;
  margin: 0;
`;

export const LocationHighlightText = styled(LocationText)`
  font: normal normal bold 14px/16px Roboto;
`;
