import styled from 'styled-components/macro';

export const SolutionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;

export const SolutionCell = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background: #fff;
  flex-wrap: wrap;
  @media (max-width: 950px) {
    flex-basis: 100%;
  }
`;

export const SolutionTitle = styled.h3`
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.15px;
  margin: 0 0 10px 0;
`;

export const SolutionDescription = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
`;

export const SolutionImage = styled.img`
  width: 100%;
  align-self: center;
  flex: 1;
`;
