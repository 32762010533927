import { Grid, Tooltip } from '@mui/material';
import styled from 'styled-components/macro';

import { Button } from 'vatix-ui/lib/components/Button';

export const StyledTooltip = styled(Tooltip)`
  margin-left: 5px;
  color:#0772E5
  white-space: pre-line;
`;

export const HeaderGrid = styled(Grid)`
  background-color: #fff;
  padding: 16px 20px;
  max-height: 60px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
`;

export const Title = styled.p`
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
`;

export const TaskRecordContainer = styled.div<{ $editing?: boolean }>`
  padding: 16px 20px 20px;
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding-top: ${(props) => props.$editing && '0'};
  border-top: 0;
`;

export const TaskRecordRow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RecordRow = styled.div`
  display: flex;
  align-items: baseline;
  min-height: 24px;
`;

export const FirstColumn = styled.div`
  display: flex;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 143%;
  letter-spacing: 0.17px;
  margin-right: 64px;
`;

export const SecondColumn = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
`;

export const RedirectButton = styled(Button)`
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
`;
