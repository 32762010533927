import React from 'react';

import dayjs from 'dayjs';

import CustomDatePicker from 'containers/IncidentDetails/components/CustomDatePicker';

import { Container } from './styles';
import { PickerProps } from '../types';

const manipulateDays = (value: string | null, days: number): number | null => {
  if (value === null) {
    return null;
  }
  const date = new Date(Number(value));
  date.setDate(date.getDate() + 1 * days);
  return date.getTime();
};

export const dateRangePickerValue = (value: [number, number]): string =>
  `${dayjs(manipulateDays((value[0] as unknown) as string, -1)).format('DD/MM/YYYY')} - ${dayjs(
    manipulateDays((value[1] as unknown) as string, -1)
  ).format('DD/MM/YYYY')}`;

export const DatePickerRange: React.FunctionComponent<PickerProps> = ({ applyValue, item, ...props }) => {
  let [from, to] = (item.value as [string, string]) || [null, null];
  return (
    <Container>
      <p style={{ margin: 0 }}>from</p>
      <CustomDatePicker
        description=""
        {...props}
        onChange={(newValue) => {
          if (newValue !== 'Invalid date') {
            applyValue({ ...item, value: [manipulateDays(newValue as string, 1), to] });
            from = newValue as string;
          }
        }}
        value={manipulateDays(from, -1)}
      />
      <p style={{ margin: 0 }}>to</p>
      <CustomDatePicker
        description=""
        {...props}
        onChange={(newValue) => {
          if (newValue !== 'Invalid date') {
            applyValue({ ...item, value: [from, manipulateDays(newValue as string, 1)] });
            to = newValue as string;
          }
        }}
        value={manipulateDays(to, -1)}
      />
    </Container>
  );
};
