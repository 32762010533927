import React from 'react';

import Tooltip from '@mui/material/Tooltip';

import { Title, MandatoryQuestion, StyledIconButton, StyledInfoIcon } from './styles';

const FieldTitle: React.FunctionComponent<{
  description: string;
  required: boolean;
  infoText?: string;
}> = ({ description, required, infoText }): React.ReactElement => (
  <Title id="filed-description">
    {description}
    {required ? <MandatoryQuestion id="mandatory-filed">*</MandatoryQuestion> : null}
    {infoText ? (
      <Tooltip title={infoText} arrow placement="right">
        <StyledIconButton>
          <StyledInfoIcon />
        </StyledIconButton>
      </Tooltip>
    ) : null}
  </Title>
);

export default FieldTitle;
