import styled from 'styled-components/macro';

import { Grid } from '@mui/material';

export const BackgroundInformationSections = styled.div`
  position: relative;
  overflow-y: scroll;

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledRowTitle = styled.p`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 14px;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 24px;
`;

export const SectionHeader = styled(Grid)`
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  margin-top: 16px;
  background: rgba(7, 114, 229, 0.04);
  border-radius: 5px;
  height: 29px;
  p {
    color: #0772e5;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: 0.15px;
    margin: 0px;
  }
`;
